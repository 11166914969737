import HomeAbout from './components/homeAbout'
import HomeAcademics from './components/homeAcademics'
import HomeContact from './components/homeContact'
import HomeJoin from './components/homeJoin'
import HomeMedia from './components/homeMedia'
import HomeSuccess from './components/homeSuccess'

export default function Home () {
  return (
    <>
      <HomeAbout />
      <HomeAcademics />
      <HomeSuccess />
      <HomeMedia />
      <HomeJoin />
      {/* <HomeContact /> */}
    </>
  )
}
