import Title from '../repeatable/title'
import SuccessCard from './successCard'
import img1 from '../../assets/img/gevorg.jpg'
import img2 from '../../assets/img/davit.jpg'
import img3 from '../../assets/img/hayk.jpg'
import img4 from '../../assets/img/sevak.jpg'
export default function HomeSuccess () {
  const list = [
    {
      image: img1,
      title: 'Gevorg Margaryan’s achievement',
      text: '20 year old Gevorg Margaryan is from the village of Avshar in the Ararat province. After graduating from school...'
    },
    {
      image: img2,
      title: 'The result of hard work',
      text: '22-year-old Davit is from Vanadzor; he received his education in his hometown; first he went to school, then Vanadzor State...'
    },
    {
      image: img3,
      title: 'The volunteer...',
      text: 'Hayk studied at the Faculty of Physics and Technology of the Slavonic University, earning his master’s degree in informational...'
    },
    {
      image: img4,
      title: 'Exceptional move',
      text: '32-year-old Sevak Movsisyan is from the village of Berdashen in the Martuni region of Artsakh. He attended the Monte Melkonyan...'
    }
  ]

  return (
    <>
      <div className='max-w-screen-xl mx-auto max-xl:px-6' id='Success'>
        <Title title='Our Success' />
        <div className='flex mt-12 lg:space-x-6 max-lg:flex-wrap'>
          {list &&
            list.map((item, index) => {
              return (
                <SuccessCard
                  image={item.image}
                  title={item.title}
                  text={item.text}
                  key={index}
                />
              )
            })}
        </div>
      </div>
    </>
  )
}
