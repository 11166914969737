import { Typography } from '@material-tailwind/react'

export default function Title (props) {
  return (
    <>
      <Typography
        variant='h1'
        className='py-6 text-3xl font-bold font-link-title text-main-blue'
      >
        {props.title} <span className='text-white'>{props.titleStyle}</span>
      </Typography>
    </>
  )
}
