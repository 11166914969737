import Title from '../repeatable/title'
import AboutCard from './aboutCard'
import imgMission from '../../assets/img/mission.webp'
import imgVision from '../../assets/img/vision.webp'
import imgTeam from '../../assets/img/team.webp'
export default function HomeAbout () {
  const cardList = [
    {
      image: imgMission,
      text: 'Mission',
      content: [
        {
          text: 'Learning Mission non-profit organization was founded after the devastating 2020 Artsakh war. During the war, our soldiers fought bravely to defend our homeland risking their lives and wellbeing. Our goal is to provide our veterans with a holistic education with IT and English at its core, as well as extra-curricular lectures in Armenian studies, world history, and other disciplines.'
        },
        {
          text: 'We provide students with computers and monthly financial assistance, which allows them to cover certain expenses in order to concentrate on the learning process.'
        },
        {
          text: ' The organization’s priority is to create a family atmosphere which we have found helps with students psychological recovery and the educational process enabling them to reintegrate into society and give back to the community.'
        }
      ]
    },
    {
      image: imgVision,
      text: 'Vision',
      content: [
        {
          text: 'Foster educational development in the IT sphere, as well as Armenian history and the history of the Armenian Church'
        },
        {
          text: 'Create a large network amongst Armenia and the Armenian diaspora that provides students with opportunities, partnerships, internships, jobs and connections to become successful in the IT field'
        },
        {
          text: ' Facilitate the reintegration of veterans into society'
        },
        {
          text: ' Expand the field of available professions for Armenian youth through educational programs.'
        },
        {
          text: 'Strengthen ties with Universities, involve the best students in internships and other opportunities'
        }
      ]
    },
    {
      image: imgTeam,
      text: 'Our Team',
      content: [
        {
          text: 'Foster educational development in the IT sphere, as well as Armenian history and the history of the Armenian Church'
        },
        {
          text: 'Create a large network amongst Armenia and the Armenian diaspora that provides students with opportunities, partnerships, internships, jobs and connections to become successful in the IT field'
        },
        {
          text: ' Facilitate the reintegration of veterans into society'
        },
        {
          text: ' Expand the field of available professions for Armenian youth through educational programs.'
        },
        {
          text: 'Strengthen ties with Universities, involve the best students in internships and other opportunities'
        }
      ]
    }
  ]

  return (
    <>
      <div className='max-w-screen-xl mx-auto max-xl:px-6' id='Home__About'>
        <Title title='About Us' />
        <div className='flex flex-col mt-12 space-y-12'>
          {cardList &&
            cardList.map((item, index) => {
              return (
                <AboutCard
                  image={item.image}
                  text={item.text}
                  content={item.content}
                />
              )
            })}
        </div>
      </div>
    </>
  )
}
