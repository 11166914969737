import {
  Card,
  CardHeader,
  CardBody,
  Typography
} from '@material-tailwind/react'

export default function NewsCard (props) {
  return (
    <>
      <Card className='flex-row w-full max-md:flex-col bg-main-blue'>
        <CardHeader
          shadow={false}
          floated={false}
          className='m-0 rounded-r-none md:w-2/5 shrink-0 h-96'
        >
          <img
            src={props.image}
            alt='card-image'
            className='object-cover w-full h-full'
          />
        </CardHeader>
        <CardBody>
          <Typography
            variant=''
            color='blue-gray'
            className='font-bold text-white max-md:text-md font-link-title'
          >
            {props.title}
          </Typography>
          <Typography
            color='gray'
            className='font-normal text-black max-md:overflow-auto max-md:h-48'
          >
            {props.text}
          </Typography>
        </CardBody>
      </Card>
    </>
  )
}
