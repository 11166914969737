import React from 'react'
import Title from '../repeatable/title'
import img from '../../assets/img/15.jpg'
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button
} from '@material-tailwind/react'

import itImage from '../../assets/img/it.png'
import englishLessons from '../../assets/img/english.webp'
import armImage from '../../assets/img/armenian.webp'
export default function HomeAcademics () {
  const list = [
    {
      title: 'It Education',
      text: 'C++, C#, JS, Auto Cad, UI/UX Basics, Computer HelpDesk, Project Menejment, Computer Hardware Fundamentals, Rhino 3d'
    },
    {
      title: 'English Lessons',
      text: 'English Language Studies: - Learning the English Language will open the doors for you to discover the world!  - Reading/Writing. - Conversational English. - Business Communications. - Email etiquette (writing). - How to conduct yourself in an interview.'
    },
    {
      title: 'Armenian Studies',
      text: 'Armenian History, History of the Armenian Church'
    }
  ]

  return (
    <>
      <div
        className='max-w-screen-xl mx-auto mt-12 mb-12 max-xl:px-6'
        id='Academics'
      >
        <Title title='Academics' />
        <div className=''>
          <Card className='flex-row w-full max-lg:flex-col'>
            <CardHeader
              shadow={false}
              floated={false}
              className='m-0 rounded-r-none lg:w-1/2 shrink-0'
            >
              <img
                src={img}
                alt='card-image'
                className='object-cover w-full h-full'
              />
            </CardHeader>
            <CardBody>
              {list &&
                list.map((item, index) => {
                  return (
                    <>
                      <Typography
                        variant='h4'
                        color=''
                        className='mt-3 font-bold text-main-blue font-link-title'
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        color='gray'
                        className='pl-1 mt-2 font-normal text-black max-sm:text-sm sm:pl-8'
                      >
                        {item.text}
                      </Typography>
                    </>
                  )
                })}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}
