import React from 'react'
import Header from './Pages/layouts/header'
import Home from './Pages/home'
import Footer from './Pages/layouts/footer'

function App () {
  return (
    <>
      <Header />
      <Home />
      <Footer />
    </>
  )
}

export default App
