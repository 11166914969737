import Title from '../repeatable/title'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import NewsCard from './newsCard'
import img1 from '../../assets/img/2.jpg'
import img2 from '../../assets/img/3.png'
import img3 from '../../assets/img/5.png'
export default function HomeMedia () {
  const list = [
    {
      image: img1,
      title:
        'The Union of Karabakh War Veterans awarded a diploma to Learning Mission',
      text: 'The Union of Karabakh War Veterans in Armenia has awarded a diploma to Learning Mission for providing free IT and English language training to servicemen wounded in the recent Artsakh war, as well as war-affected groups. This is the first award for the Organization. The diploma awarded by the "Union of Karabakh War Veterans" in Armenia is binding and inspiring for us to train qualified specialists for our country. The award is the first for us, which is binding. We aim to stay different with our project and unique as a socially responsible organization. We must stay that way and expand our cherished program even more, ”said the organizations co-founder Ruben Gargaloyan.'
    },
    {
      image: img2,
      title:
        'Students of Learning Mission took part in the “Victory Tree” planting ceremony',
      text: ' On the occasion of the 103rd anniversary of the Battle of Sardarapat, on May 28, the students of the Learning Mission took part in a symbolic tree planting in the "Independence Trail" of the Yerevan Botanical Garden. As fighters who took part in the last war in Artsakh, Learning Mission students were given the opportunity to plant a "Victory Tree". The event was also attended by conscripts currently in service. "Todays event is mysterious and meaningful. The young men who took part in the war plant trees under the protection of the Armenian Army. Those who defended and are defending us are in one place, which is very symbolic. We maintain the continuity of such events, we are ready to participate in various such events. We are strong with our army, of course with heroic men," said the Organizations co-founder Ruben Gargaloyan.'
    },
    {
      image: img3,
      title: 'New knowledge is a beginning for new opportunities',
      text: '  Founded after the Artsakh war in 2020, Learning Mission NGO launched a large-scale project to provide technical and high quality English training to war-torn victims and affected groups. Many war veterans now receive free education at the Organization, which will enable them to find work, opportunities and hope. In addition to education, the young men at Learning Mission also have the opportunity to participate in a variety of activities, undergo practical training and internships at leading IT companies in Armenia, as well as make new connections and friends. Details - in the video:'
    },
    {
      image: img1,
      title: 'The country that has educated youth has a bright future',
      text: '    A number of students from the American University of Armenia have joined Learning Mission to develop educational processes through mutual effectiveness. According to the students, the young men studying in the organization, who are participants of the last Artsakh war (representatives of war-affected groups also study in the organization), can teach in parallel with their studies. The students of both the American University of Armenia and Learning Mission agree that a country that has educated youth also has a bright future. Details – in the video.'
    },
    {
      image: img2,
      title: 'Concert project in honor of the students of Learning Mission',
      text: '     Yerevan Music School Named after Anahit Tsitsikyan has organized a concert project in honor of the Learning Mission students. The students of the school presented various musical performances to the students of Learning Mission. There was a surprise - Singer, songwriter Davit Amalyan was invited to the event. Details –in the video.'
    },
    {
      image: img3,
      title: 'Learning Mission has new members',
      text: '     Twenty-year-old Robert Poghosyan, who took part in the self-defense battles in Martakert during the 2020 Artsakh war, joined the large family of Learning Mission. After studying at Stepanakert University for half a year, Robert was enlisted into the army, and during the war he was one of the first to go to the defense of the Homeland with thousands of our soldiers. Robert has now applied to Learning Mission to study C ++ programming language. Due to his initial knowledge, the latter immediately joined the educational project of the organization without a probation period. "Our goal is to add many heroic fighters to the Learning Mission to integrate them into the social environment as soon as possible, as well as to provide them with a high quality education.'
    }
  ]

  return (
    <>
      <div className='max-w-screen-xl mx-auto mt-12 max-xl:px-6' id='News'>
        <Title title='Media' />
        <div className='mt-12'>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={swiper => console.log(swiper)}
          >
            {list &&
              list.map((item, index) => {
                return (
                  <SwiperSlide className='' key={index}>
                    <NewsCard
                      image={item.image}
                      title={item.title}
                      text={item.text}
                    />
                  </SwiperSlide>
                )
              })}
          </Swiper>
        </div>
      </div>
    </>
  )
}
