import Title from '../repeatable/title'
import React from 'react'
import Logo from '../../assets/img/logo.png'
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Input,
  Textarea
} from '@material-tailwind/react'
import { Button } from 'react-scroll'

function Icon ({ id, open }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={2}
      stroke='currentColor'
      className={`${
        id === open ? 'rotate-180' : ''
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M19.5 8.25l-7.5 7.5-7.5-7.5'
      />
    </svg>
  )
}

export default function HomeJoin () {
  const [open, setOpen] = React.useState(0)

  const handleOpen = value => setOpen(open === value ? 0 : value)

  return (
    <>
      <div className='max-w-screen-xl mx-auto mt-12 max-xl:px-6' id='Join'>
        <Title title='Join Us' />
        <div className='flex mt-12 bg-main-blue rounded-xl max-md:flex-col'>
          <div className='p-6 py-12 mt-5 md:w-1/2'>
            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(1)}
                className='text-white font-link-title'
              >
                Who can apply?
              </AccordionHeader>
              <AccordionBody className='text-lg font-medium text-black'>
                We&apos;re not always in the position that we want to be at.
                We&apos;re constantly growing. We&apos;re constantly making
                mistakes. We&apos;re constantly trying to express ourselves and
                actualize our dreams.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(2)}
                className='text-white font-link-title'
              >
                Intership
              </AccordionHeader>
              <AccordionBody className='text-lg font-medium text-black'>
                We&apos;re not always in the position that we want to be at.
                We&apos;re constantly growing. We&apos;re constantly making
                mistakes. We&apos;re constantly trying to express ourselves and
                actualize our dreams.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(3)}
                className='text-white font-link-title'
              >
                Networking
              </AccordionHeader>
              <AccordionBody className='text-lg font-medium text-black'>
                We&apos;re not always in the position that we want to be at.
                We&apos;re constantly growing. We&apos;re constantly making
                mistakes. We&apos;re constantly trying to express ourselves and
                actualize our dreams.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(4)}
                className='text-white font-link-title'
              >
                Become an instructor
              </AccordionHeader>
              <AccordionBody className='text-lg font-medium text-black'>
                We&apos;re not always in the position that we want to be at.
                We&apos;re constantly growing. We&apos;re constantly making
                mistakes. We&apos;re constantly trying to express ourselves and
                actualize our dreams.
              </AccordionBody>
            </Accordion>
          </div>
          <div className='px-6 pb-12 md:w-1/2'>
            <Title titleStyle='Contact Us' />
            <form action='' className='flex flex-col space-y-2 text-white'>
              <Input
                variant='standard'
                className='text-white placeholder:text-white'
                label='Full Name'
              />
              <Input
                variant='standard'
                className='text-white placeholder:text-white'
                label='Email'
                type='email'
              />
              <Input
                variant='standard'
                className='text-white placeholder:text-white'
                label='Phone'
                type='number'
              />
              <div className=''>
                <Textarea variant='standard' label='Message' />
              </div>
              <Input
                variant='standard'
                className='!pt-0 text-xl font-bold bg-white rounded-lg text-main-blue'
                type='submit'
                value='Send'
              />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
