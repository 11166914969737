import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button
} from '@material-tailwind/react'

export default function AboutCard (props) {
  return (
    <>
      <Card className='w-full lg:flex-row'>
        <CardHeader
          shadow={false}
          floated={false}
          className='m-0 lg:rounded-r-none lg:w-1/2 shrink-0'
        >
          <img src={props.image} alt='card-image' className='w-full h-96' />
        </CardHeader>
        <CardBody className='lg:w-1/2'>
          <Typography
            variant='h4'
            color=''
            className='font-bold font-link-title text-main-blue'
          >
            {props.text}
          </Typography>
          {props.content &&
            props.content.map((item, index) => {
              return (
                <Typography color='black' className='mt-1 font-medium '>
                  {item.text}
                </Typography>
              )
            })}
        </CardBody>
      </Card>
    </>
  )
}
