import HeaderBanner from '../components/headerBanner'
import Logo from '../../assets/img/logo.png'
import React from 'react'
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
  Collapse
} from '@material-tailwind/react'
import { Link } from 'react-scroll'

export default function Header () {
  const [openNav, setOpenNav] = React.useState(false)

  React.useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && setOpenNav(false)
    )
  }, [])
  const navList = (
    <ul className='flex flex-col gap-2 mt-2 mb-4 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6'>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          activeClass='active'
          to='Home__About'
          spy={true}
          smooth={true}
          offset={-400}
          duration={500}
          className='flex items-center font-link-title text-xl text-main-blue font-semibold cursor-pointer relative after:transition-all after:duration-300  after:absolute after:w-0 after:h-[2px] after:left-0 after:bottom-[-2px] after:bg-main-blue hover:after:w-full'
        >
          About
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          activeClass='active'
          to='Academics'
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className='flex items-center font-link-title text-xl text-main-blue font-semibold cursor-pointer relative after:transition-all after:duration-300  after:absolute after:w-0 after:h-[2px] after:left-0 after:bottom-[-2px] after:bg-main-blue hover:after:w-full'
        >
          Academics
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          activeClass='active'
          to='Success'
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className='flex items-center font-link-title text-xl text-main-blue font-semibold cursor-pointer relative after:transition-all after:duration-300  after:absolute after:w-0 after:h-[2px] after:left-0 after:bottom-[-2px] after:bg-main-blue hover:after:w-full'
        >
          Our Success
        </Link>
      </Typography>
      {/* <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          activeClass='active'
          to=''
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='flex items-center font-link-title text-xl text-main-blue font-semibold cursor-pointer relative after:transition-all after:duration-300  after:absolute after:w-0 after:h-[2px] after:left-0 after:bottom-[-2px] after:bg-main-blue hover:after:w-full'
        >
          Our Achivers
        </Link>
      </Typography> */}
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          activeClass='active'
          to='News'
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className='flex items-center font-link-title text-xl text-main-blue font-semibold cursor-pointer relative after:transition-all after:duration-300  after:absolute after:w-0 after:h-[2px] after:left-0 after:bottom-[-2px] after:bg-main-blue hover:after:w-full'
        >
          News
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 font-normal'
      >
        <Link
          activeClass='active'
          to='Join'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className='flex items-center font-link-title text-xl text-main-blue font-semibold cursor-pointer relative after:transition-all after:duration-300  after:absolute after:w-0 after:h-[2px] after:left-0 after:bottom-[-2px] after:bg-main-blue hover:after:w-full'
        >
          Join Us
        </Link>
      </Typography>
    </ul>
  )

  return (
    <>
      {/* <HeaderBanner /> */}
      <div className='w-full py-4 '>
        <div className='mx-auto max-w-screen-2xl'>
          <Navbar
            className='max-w-screen-xl px-4 py-2 mx-auto lg:px-8 lg:py-4'
            id='Nav__Menu'
          >
            <div className='container flex items-center justify-between mx-auto text-blue-gray-900'>
              <Link>
                <img src={Logo} alt='' className='w-48 sm:w-72' />
              </Link>
              <div className='hidden lg:block'>{navList}</div>
              <IconButton
                variant='text'
                className='w-6 h-6 ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden'
                ripple={false}
                onClick={() => setOpenNav(!openNav)}
              >
                {openNav ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    className='w-6 h-6'
                    viewBox='0 0 24 24'
                    stroke='#21409a'
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='w-6 h-6'
                    fill='none'
                    stroke='#21409a'
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M4 6h16M4 12h16M4 18h16'
                    />
                  </svg>
                )}
              </IconButton>
            </div>
            <Collapse open={openNav}>
              <div className='container mx-auto'>{navList}</div>
            </Collapse>
          </Navbar>
        </div>
      </div>
    </>
  )
}
