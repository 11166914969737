import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button
} from '@material-tailwind/react'

export default function SuccessCard (props) {
  return (
    <>
      <Card className='md:w-1/2 max-lg:mt-12 lg:w-1/4'>
        <CardHeader color='blue-gray' className='relative h-56'>
          <img
            src={props.image}
            className='object-cover w-full h-full'
            alt='card-image'
          />
        </CardHeader>
        <CardBody>
          <Typography
            variant=''
            color='blue-gray'
            className='mb-2 font-bold text-main-blue font-link-title'
          >
            {props.title}
          </Typography>
          <Typography className='font-medium text-black'>
            {props.text}
          </Typography>
        </CardBody>
        <CardFooter className='pt-0'>
          <Button className='bg-main-blue'>Read More</Button>
        </CardFooter>
      </Card>
    </>
  )
}
